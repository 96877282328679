/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "tailscale 활용법", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/"
  }, "tailscale")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/kb/1123/zero-trust/"
  }, "tailscale: \"Zero Trust Networking\" definition")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/kb/1103/exit-nodes/"
  }, "tailscale: Exit Nodes (route all traffic)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/kb/1015/100.x-addresses/"
  }, "tailscale: What are these 100.x.y.z addresses?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ivanti.com/products/connect-secure-vpn"
  }, "ivanti Remote Access VPN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://research.google/pubs/pub43231/"
  }, "BeyondCorp: A New Approach to Enterprise Security")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "CentOS와 오픈소스", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.centos.org/centos-linux-eol/"
  }, "CentOS Linux EOL")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.redhat.com/ko/topics/linux/what-is-centos-stream"
  }, "CentOS Stream이란?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rockylinux.org/news/keeping-open-source-open/"
  }, "Rocky Linux: Keeping Open Source Open")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2023/07/3-151/"
  }, "[심재석의 입장] 지금 오픈소스 업계에서 벌어지는 논쟁")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.redhat.com/en/blog/furthering-evolution-centos-stream"
  }, "Furthering the evolution of CentOS Stream")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/amazon-linux-2023-a-cloud-optimized-linux-distribution-with-long-term-support/"
  }, "Amazon Linux 2023")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Threads, 새로운 트위터가 될 것인가?", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.threads.net/"
  }, "Threads")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.instagram.com/blog/announcements/threads-instagram-text-feature"
  }, "Introducing Threads: A New Way to Share with Text")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/news/technology-66077195"
  }, "Twitter temporarily restricts tweets users can see, Elon Musk announces")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
